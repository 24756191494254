import { startTransition, useEffect, useMemo, useState } from 'react';

import { useRouter } from 'next/router';
import { useGeolocated } from 'react-geolocated';
import useSWR from 'swr';

import {
  RetailersApiResponse,
  Store,
  StoreCoords,
} from '@hultafors/shared/types';
import { MapChangedData } from '@hultafors/shared/types/map-changed-data';

export type FindRetailersModeType = 'findRetailersByCoord' | 'findRetailers';

export const findRetailersEndpoints: Record<FindRetailersModeType, string> = {
  findRetailers: '/api/findRetailers',
  findRetailersByCoord: '/api/findRetailersByCoord',
};

export const findRetailersMode: Record<
  FindRetailersModeType,
  FindRetailersModeType
> = {
  findRetailers: 'findRetailers',
  findRetailersByCoord: 'findRetailersByCoord',
};

interface UseFindRetailers {
  mode: FindRetailersModeType | undefined;
  stores: Store[];
  isExpandable: boolean | undefined;
  averageCoords: StoreCoords | undefined;
  error: any;
  loading: boolean;
  validating: boolean;
  international: boolean;
  setInternational(value: boolean): void;
  radius: number | undefined;
  setRadius(value: number): void;
  term: string;
  setTerm(value: string): void;
  coords: StoreCoords;
  clear(): void;
  onMapChange(data: MapChangedData): void;
}

interface UseFindRetailersProps {
  defaultRadius: number;
  radiusOptions: number[];
  defaultCoords: StoreCoords;
  defaultTerm?: string;
  defaultInternational?: boolean;
  deepLink?: boolean;
}

const DEFAULT_RADIUS = 50;

/**
 * Hook for find retailers api.
 * Important! Needs api routes set up!
 */
export const useFindRetailers = ({
  defaultRadius = DEFAULT_RADIUS,
  radiusOptions = [],
  defaultCoords,
  defaultTerm = '',
  defaultInternational = false,
  deepLink = false,
}: UseFindRetailersProps): UseFindRetailers => {
  const getValidRadius = (input: number | string): number => {
    const parsedInput = parseInt(`${input}`, 10);
    return parsedInput && radiusOptions.includes(parsedInput)
      ? parsedInput
      : defaultRadius;
  };

  const router = useRouter();

  const [radius, setRadiusInternal] = useState<number>(defaultRadius);
  const [term, setTermInternal] = useState<string>(defaultTerm);
  const [international, setInternationalInternal] =
    useState<boolean>(defaultInternational);

  function setRadius(value: number) {
    setRadiusInternal((existingValue) => {
      if (existingValue && value === existingValue) {
        return existingValue;
      }
      const newRadius =
        getValidRadius(value) || defaultRadius || DEFAULT_RADIUS;
      if (deepLink) {
        updateUrl(new Map([['radius', newRadius]]));
      }
      return newRadius;
    });
  }

  function setTerm(value: string) {
    setTermInternal((existingValue) => {
      if (existingValue && value === existingValue) {
        return existingValue;
      }
      if (deepLink) {
        updateUrl(new Map([['search', value]]));
      }
      return value;
    });
  }

  function setInternational(value: boolean) {
    setInternationalInternal((existingValue) => {
      if (value === existingValue) {
        return existingValue;
      }
      if (deepLink) {
        updateUrl(new Map([['international', value]]));
      }
      return value;
    });
  }

  useEffect(() => {
    const newParams = new Map<string, string | number | boolean>();
    let initialRadius = getValidRadius(defaultRadius || DEFAULT_RADIUS);
    if (
      deepLink &&
      router.query?.['radius'] &&
      typeof router.query['radius'] === 'string'
    ) {
      initialRadius =
        (router?.query?.['radius'] && getValidRadius(router.query['radius'])) ||
        defaultRadius ||
        DEFAULT_RADIUS;
    }
    if (
      deepLink &&
      initialRadius.toString() !== router.query?.['radius']?.toString()
    ) {
      newParams.set('radius', initialRadius);
    }

    let initialTerm = defaultTerm || '';
    if (
      deepLink &&
      router.query?.['search'] &&
      typeof router.query['search'] === 'string'
    ) {
      initialTerm = router.query['search'];
    }
    if (deepLink && initialTerm !== router.query?.['search']?.toString()) {
      newParams.set('search', initialTerm);
    }

    let initialInternational = defaultInternational || false;
    if (deepLink && router.query?.['international']) {
      initialInternational =
        router.query?.['international']?.toString()?.toLowerCase() === 'true';
    }
    if (
      deepLink &&
      initialInternational &&
      initialInternational.toString() !==
        router.query?.['international']?.toString()?.toLowerCase()
    ) {
      newParams.set('international', initialInternational);
    }

    if (deepLink && newParams.size) {
      updateUrl(newParams);
    }

    startTransition(() => {
      setRadiusInternal(initialRadius);
      setTermInternal(initialTerm);
      setInternationalInternal(initialInternational);
    });
  }, []);

  const {
    coords: geoCords,
    isGeolocationAvailable,
    isGeolocationEnabled,
    getPosition,
  } = useGeolocated({
    positionOptions: {
      enableHighAccuracy: false,
    },
    suppressLocationOnMount: true,
    userDecisionTimeout: 30000,
    watchLocationPermissionChange: true,
  });
  useEffect(() => {
    if (!term && isGeolocationAvailable && !geoCords) {
      getPosition();
    }
  }, [isGeolocationAvailable, isGeolocationEnabled, term]);

  const [coords, setCoords] = useState<StoreCoords>(defaultCoords);

  const key: string | null = useMemo(() => {
    let baseUrl = findRetailersEndpoints.findRetailers;
    const params = new URLSearchParams();
    if (radius) {
      params.set('radius', `${radius}`);
    }
    if (term) {
      params.set('search', term);
    }
    if (!term && coords?.latitude && coords?.longitude) {
      params.set('latitude', `${coords.latitude}`);
      params.set('longitude', `${coords.longitude}`);
      baseUrl = findRetailersEndpoints.findRetailersByCoord;
    }
    if (international) {
      params.set('international', 'true');
    }

    const url = [baseUrl, params.toString()].join('?');
    if (url?.length) {
      return url;
    }
    return null;
  }, [international, radius, term, coords]);

  const mode = useMemo(() => {
    if (key?.startsWith(findRetailersEndpoints.findRetailersByCoord)) {
      return findRetailersMode.findRetailersByCoord;
    }
    if (key?.startsWith(findRetailersEndpoints.findRetailers)) {
      return findRetailersMode.findRetailers;
    }
    return undefined;
  }, [key]);

  const { data, error, isValidating, isLoading } = useSWR<RetailersApiResponse>(
    key,
    {
      revalidateIfStale: true,
      revalidateOnMount: true,
      revalidateOnReconnect: true,
    },
  );

  useEffect(() => {
    const value = { ...coords };

    if (isGeolocationEnabled && geoCords?.latitude && geoCords?.longitude) {
      value.latitude = geoCords.latitude;
      value.longitude = geoCords.longitude;
    }

    if (data?.averageCoords?.latitude && data?.averageCoords?.longitude) {
      value.latitude = data.averageCoords.latitude;
      value.longitude = data.averageCoords.longitude;
    }

    if (
      coords.latitude.toString() !== value.latitude.toString() ||
      coords.longitude.toString() !== value.longitude.toString()
    ) {
      setCoords((oldValue) => {
        if (
          oldValue.latitude.toString() === value.latitude.toString() &&
          oldValue.longitude.toString() === value.longitude.toString()
        ) {
          return oldValue;
        }
        return value;
      });
    }
  }, [geoCords, data, isGeolocationEnabled]);

  const stores: Store[] = useMemo(() => {
    return data?.stores || [];
  }, [data]);

  function updateUrl(values: Map<string, string | number | boolean>) {
    const [url, query = ''] = router.asPath.split('?');
    const params = new URLSearchParams(query);
    values.forEach((value, key) => {
      params.set(key, value.toString());
    });
    router.replace(
      [url, params.toString()].filter(Boolean).join('?'),
      undefined,
      {
        shallow: true,
      },
    );
  }

  const clear = () => {
    setTerm('');
    setRadius(defaultRadius);
  };

  function onMapChange(data: MapChangedData) {
    const [longitude, latitude] = data?.center || [];
    if (typeof latitude !== 'undefined' && typeof longitude !== 'undefined') {
      // TODO: Make map not jump
      setCoords((oldValue) => {
        if (
          oldValue.latitude.toString() === latitude.toString() &&
          oldValue.longitude.toString() === longitude.toString()
        ) {
          return oldValue;
        }
        return { latitude, longitude };
      });
    }
  }

  return useMemo(() => {
    return {
      averageCoords: data?.averageCoords,
      clear,
      coords,
      error,
      international,
      isExpandable: data?.isExpandable,
      loading: isLoading,
      mode,
      onMapChange,
      radius,
      setInternational,
      setRadius,
      setTerm,
      stores,
      term,
      validating: isValidating,
    };
  }, [
    {
      clear,
      coords,
      data,
      error,
      international,
      isLoading,
      isValidating,
      mode,
      onMapChange,
      radius,
      setInternational,
      setRadius,
      setTerm,
      stores,
      term,
    },
  ]);
};
